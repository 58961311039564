import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false,
        themes: {
          light: {
            primary: '#6CC8A0',
            secondary: '#A6CC39',
            tableColor: '#E6E6E6'
          },
        },
      },
});
