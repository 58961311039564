const quiz = {
    state: () => ({
        quizEdit: {},
        certificateElementsEditData: [],
    }),

    getters: {
        quiz(state) {
            return state.quizEdit
        },
        certificateElementsEditData(state) {
            return state.certificateElementsEditData
        }
    },

    mutations: {
        saveQuiz(state, data) {
            state.quizEdit = data
        },
        saveCertElementsToEdit(state, data) {            
            state.certificateElementsEditData = data
        },
        removeCertElementsData(state) {
            state.certificateElementsEditData = []
        }
    }
}

export default quiz;