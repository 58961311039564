const departmentone = {
    state: () => ({ 
        editNodeptData: [],
        addNoUserDepartmentDialog: false,
        editNoUserDepartmentDialog: false,
        editNoUserDepartment: false,
        startingNoUserDepartmentActive: false,
        showNoUserDepartmentTable: false,
        showEditUserDepartmentTable: false,
        selectedUserList:[],
        users: [],
        EditselectedUserList: [],
        userManagementTab: 'tab-1',

        showDepartmentOneTable:false
    }),

    getters: {
        editNodeptData(state){
            return state.editNodeptData
        },
        addNoUserDepartmentDialog(state){
            return state.addNoUserDepartmentDialog
        },
        editNoUserDepartmentDialog(state){
            return state.editNoUserDepartmentDialog
        },
        selectedUserList(state){
            return state.selectedUserList
        },
        editNoUserDepartment(state){
            return state.editNoUserDepartment
        },
        startingNoUserDepartmentActive(state){
            return state.startingNoUserDepartmentActive
        },
        showNoUserDepartmentTable(state){
            return state.showNoUserDepartmentTable
        },
        showEditUserDepartmentTable(state){
            return state.showEditUserDepartmentTable
        },
        getUsers: state => state.users,
        getEditUsers: state => state.EditselectedUserList,
        // tabs: state =>  state.userManagementTab,
        showDepartmentOneTable(state){
            return state.showDepartmentOneTable
        }
    },

    mutations: { 
        openAddNoDepartmentDialog(state){ 
            state.addNoUserDepartmentDialog = true
        },

        openEditNoDepartmentDialog(state){ 
            state.editNoUserDepartmentDialog = true
        },

        closeAddDepartmentDialog(state){ 
            state.addNoUserDepartmentDialog = false
        },

        closeEditDepartmentDialog(state){ 
            state.editNoUserDepartmentDialog = false
        },
        
        saveSelectedUser(state,data){ 
            state.selectedUserList = data
        },

        saveEditDepartmentData(state,data){
            state.editNodeptData = data;
        },
        
        editNoUserDepartment(state,data){
            state.editNoUserDepartment = data;
        },
        removeDepartmentGroupEmployeeEditData(state){
            state.editNodeptData.groupEmployee = {}
        },

        openStartingDepartmentActive(state){
            state.startingNoUserDepartmentActive = true
        },

        closeStartingDepartmentActive(state){
            state.startingNoUserDepartmentActive = false
        },
        clearSelected(state){
            state.selectedUserList = []
        },
        clearEditDepartmentData(state){
            state.editNodeptData = {}
        },

        DepartmentTable(state,data){
            state.showNoUserDepartmentTable = data;
        },
        EditDepartmentTable(state,data){
            state.showEditUserDepartmentTable = data;
        },
        NodeptUser(state, users) {
            state.users = users;
        },
        EditselectedUserListdata(state, EditselectedUserList) {
            state.EditselectedUserList = EditselectedUserList
        },
        changeTab(state,data){
            state.userManagementTab = data
        },
        EditDepartmentOneTable(state,data){
            state.showDepartmentOneTable = data;
        }
    },
    
}

export default departmentone;