const department = {
    state: () => ({ 
        editDepartmentData: [],
        addDepartmentDialog: false,
        editDepartmentDialog: false,
        editDepartment: false,
        startingDepartmentActive: false,
        showDepartmentTable: false,
        selectedMemberList:[],
        UserList: [],
        SearchandBtnFlag: true,
    }),

    getters: { 
        editDepartmentData(state){
            return state.editDepartmentData
        },
        addDepartmentDialog(state){
            return state.addDepartmentDialog
        },
        editDepartmentDialog(state){
            return state.editDepartmentDialog
        },
        selectedMemberList(state){
            return state.selectedMemberList
        },
        editDepartment(state){
            return state.editDepartment
        },
        startingDepartmentActive(state){
            return state.startingDepartmentActive
        },
        showDepartmentTable(state){
            return state.showDepartmentTable
        },
        UserList(state){
            return state.UserList
        },
        SearchandBtnFlag(state){
            return state.SearchandBtnFlag
        }
    },

    mutations: { 
        openAddDepartmentDialog(state){ 
            state.addDepartmentDialog = true
        },

        openEditDepartmentDialog(state){ 
            state.editDepartmentDialog = true
        },

        closeAddDepartmentDialog(state){ 
            state.addDepartmentDialog = false
        },

        closeEditDepartmentDialog(state){ 
            state.editDepartmentDialog = false
        },
        
        saveSelectedDepartmentItem(state,data){ 
            state.selectedMemberList = data
        },

        saveEditDepartmentData(state,data){
            state.editDepartmentData = data;
        },
        
        editDepartment(state,data){
            state.editDepartment = data;
        },
        removeDepartmentGroupEmployeeEditData(state){
            state.editDepartmentData.groupEmployee = {}
        },

        openStartingDepartmentActive(state){
            state.startingDepartmentActive = true
        },

        closeStartingDepartmentActive(state){
            state.startingDepartmentActive = false
        },

        clearSelected(state){
            state.selectedMemberList = []
        },
        clearEditDepartmentData(state){
            state.editDepartmentData = {}
        },

        DepartmentTable(state,data){
            state.showDepartmentTable = data;
        },

        saveUserList(state,data){            
            state.UserList = data
        },
        saveSearchandBtnFlag(state,data){
         state.SearchandBtnFlag = data
        }
    },
    
}

export default department;