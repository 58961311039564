const video = {
    state: () => ({
        editVideoData: {}
    }),

    getters:{
        editVideoData(state){
            return state.editVideoData
        }
    },

    mutations:{
        saveEditVideo(state,data){
            state.editVideoData = data
        }
    }
}

export default video;