<template>
    <div>
        <v-dialog persistent width="600" v-model="dialog">
            <v-card>
            <v-card-title class="white--text" style="background-color:red">
                <v-row justify="center" no-gutters>
                <p style="font-size:30px;">{{title}}</p>
                </v-row>
            </v-card-title>
            <v-card-text>
                <p class="pt-4 text-center" style="font-size:16px;color:#4D4F5C">{{text}}</p>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-row justify="center" no-gutters>
                <v-btn color="#a6cc39" style="font-size:20px" class="text-capitalize white--text" width="150" @click="$emit('close')">{{$t('ok')}}</v-btn>
                </v-row>
            </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
export default {
    props:{
        title:{
            type: String,
            default: null
        },
        text:{
            type: String,
            default: null
        },
        show:{
            type: Boolean,
            default: false
        }
    },
    computed:{
        dialog:{
            get(){
                return this.show
            },
            set(){
                this.$emit('close');
            }
        }
    }
}
</script>
<style scoped>
::v-deep .v-dialog {
  border-radius: 35px !important;
}
</style>