const level = {
    state: () => ({ 
        addLevelDialog: false,
        editLevelDialog: false,
        selectedLevelPermission: [],
        levelList:[],
        editLevelPermission: {},
        editLevel: false,
        showPermissionTable: false
    }),

    getters: { 
        addLevelDialog(state){
            return state.addLevelDialog
        },
        levelList(state) {
            return state.levelList
        },
        editLevelDialog(state){
            return state.editLevelDialog
        },
        selectedLevelPermission(state){
            return state.selectedLevelPermission
        },
        editLevelPermission(state){
            return state.editLevelPermission
        },
        editLevel(state){
            return state.editLevel
        },
        showPermissionTable(state){
            return state.showPermissionTable
        }
    },

    mutations: { 
        openLevelAddDialog(state){
            state.addLevelDialog = true;
        },
        saveroleList(state, data) {
            state.levelList = data;
        },
        closeLevelAddDialog(state){
            state.addLevelDialog = false;
        },

        openLevelEditDialog(state){
            state.editLevelDialog = true;
        },

        closeLevelEditDialog(state){
            state.editLevelDialog = false;
        },

        saveSelectedLevelPermission(state,data){
            state.selectedLevelPermission = data;
        },

        removeSelectedLevelPermission(state){
            state.selectedLevelPermission = []
        },

        saveEditLevelPermission(state,data){
            state.editLevelPermission = data;
        },

        changeEditLevel(state,data){
            state.editLevel = data
        },

        changePermissionTable(state,data){
            state.showPermissionTable = data
        }
    },
    
}

export default level;