const article = {
    state: () => ({
        editArticleData: {},
        showFirstTimeEditDataForArticle: true
    }),

    getters:{
        editArticleData(state){
            return state.editArticleData
        },
        showFirstTimeEditDataForArticle(state){
            return state.showFirstTimeEditDataForArticle
        }
    },

    mutations:{
        saveEditArticle(state,data){
            state.editArticleData = data
        },

        changeArticleGroup(state,data){
            state.editArticleData.isUserGroup = data;
        },
        
        showFirstTimeEditDataForArticle(state){
            state.showFirstTimeEditDataForArticle = true
        },

        removeFirstTimeEditDataForArticle(state){
            state.showFirstTimeEditDataForArticle = false
        }
    }
}

export default article;