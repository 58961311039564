const assignment = {
  state: () => ({
    selectedvideoList: [],
    articleList: [],
    SelectedArticle: [],
    editSelectedArticle: {},
    LibraryTopicList: [],
  }),

  getters: {
    selectedvideoList(state) {
      return state.selectedvideoList;
    },
    articleList(state) {
      return state.articleList;
    },
    SelectedArticle(state) {
      return state.SelectedArticle;
    },
    editSelectedArticle(state) {
      return state.editSelectedArticle;
    },
    LibraryTopicList(state) {
      return state.LibraryTopicList;
    },
  },

  mutations: {
    saveSelectedvideoList(state, data) {
      state.selectedvideoList = data;
    },

    removeSelectedvideoList(state) {
      state.selectedvideoList = [];
    },

    saveArticleList(state, data) {
      state.articleList = data;
    },

    removeArticleList(state) {
      state.articleList = [];
    },

    saveSelectedArticle(state, data) {
      state.SelectedArticle = data;
    },
    removeSelectedArticle(state) {
      state.SelectedArticle = [];
    },

    saveEditSelectedArticle(state, data) {
      state.editSelectedArticle = data;
    },
    removeEditSelectedArticle(state) {
      state.editSelectedArticle = {};
    },

    saveLibraryTopicList(state, data) {
      state.LibraryTopicList = data;
    },
    removeLibraryTopicList(state) {
      state.LibraryTopicList = [];
    },
  },
};

export default assignment;
