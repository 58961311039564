<template>
  <div>
    <!-- nav-bar flat #6CC8A0-->
    <v-app-bar color="#A6CC39" clipped-left app>
      <v-spacer></v-spacer>

      <div
        class="mr-5"
        style="margin-top: 10px; color: #ffffff; cursor: pointer"
      >
        <img
          src="@/assets/images/2@2x.png"
          alt="header-user"
          height="25px"
          width="25px"
          @click="goto()"
        />
      </div>
      <v-divider
        vertical
        inset
        style="border-color: #ffffff; opacity: 0.4"
        class="mr-4"
      ></v-divider>
      <div v-if="notiCount != 0" class="mr-5">
        <v-badge
          color="red"
          :content="notiCount"
          overlap
          style="cursor: pointer"
        >
          <v-icon style="color: #ffffff" center @click="$router.push('/inbox')"
            >mdi-bell</v-icon
          >
        </v-badge>
      </div>

      <div v-else class="mr-5">
        <v-icon style="color: #ffffff" center @click="$router.push('/inbox')"
          >mdi-bell</v-icon
        >
      </div>
      <v-divider
        vertical
        inset
        style="border-color: #ffffff; opacity: 0.4"
        class="mr-4"
      ></v-divider>
      <div>
        <span
          style="
            color: #ffffff;
            padding-right: 1rem;
            font-size: 18px;
            text-transform: none;
          "
          >{{ companyName }}</span
        >
      </div>
      <v-divider
        vertical
        inset
        style="border-color: #ffffff; opacity: 0.4"
      ></v-divider>
      <!-- New Language Edit Design -->
      <v-dialog v-model="languageDialog" width="340">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            text
            dark
            v-on="on"
            id="ButtonAnimation"
            class="ml-2 mr-2 flag-name"
          >
            <country-flag
              size="normal"
              :country="currentflag"
              class="flagpos"
            />
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="lighten-2">
            <v-icon class="mr-2">mdi-square-edit-outline</v-icon>
            <span
              style="font-size:20px:font-color: #424242;font-weight: 300;"
              >{{ $t("EditLanguage") }}</span
            >
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="languageDialog = false"
              color="#424242"
              style="border-radius: 8px !important"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>

          <v-card-text class="mt-4">
            <v-row>
              <v-col style="padding: 0px">
                <div
                  class="flag-btn-dialog"
                  style="margin: 5px"
                  v-for="(entry, index) in languages"
                  :key="entry.title"
                  @click="
                    changeLocale(
                      entry.language,
                      entry.flag,
                      entry.title,
                      entry.currentname
                    ),
                      (languageDialog = false)
                  "
                >
                  <v-btn
                    v-if="index <= 2"
                    text
                    dark
                    class="mr-2 flag-name"
                    style="margin: 2px"
                  >
                    <country-flag size="normal" :country="entry.flag" />
                    <span
                      style="font-size: 16px"
                      class="text-capitalize lang-title hidden-sm-and-down"
                      >&nbsp;&nbsp;{{ entry.title }}</span
                    >
                  </v-btn>
                </div>
              </v-col>
              <v-col style="padding: 0px">
                <div
                  class="flag-btn-dialog"
                  style="margin: 5px"
                  v-for="(entry, index) in languages"
                  :key="entry.title"
                  @click="
                    changeLocale(
                      entry.language,
                      entry.flag,
                      entry.title,
                      entry.currentname
                    ),
                      (languageDialog = false)
                  "
                >
                  <v-btn
                    v-if="index >= 3"
                    text
                    dark
                    class="mr-2 flag-name"
                    style="margin: 2px"
                  >
                    <country-flag size="normal" :country="entry.flag" />
                    <span
                      style="font-size: 16px"
                      class="text-capitalize lang-title hidden-sm-and-down"
                      >&nbsp;&nbsp;{{ entry.title }}</span
                    >
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-divider
        vertical
        inset
        style="border-color: #ffffff; opacity: 0.4"
      ></v-divider>
      <!-- defaultprofile.png -->
      <v-avatar
                      justify="center"
                      align="center"
                      size="40"
                    >
                    <v-img
        v-if="userprofile.profileImage == null"
        src="@/assets/defaultprofile.png"
        alt="header-user"
      />
      <v-img
        :src="userprofile.profileImage"
        alt="header-user"
        v-if="userprofile.profileImage !== null"
      />
      </v-avatar>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            v-bind="attrs"
            v-on="on"
            id="ButtonAnimation"
            class="full-name"
          >
            <span
              style="color: #ffffff; font-size: 16px; text-transform: none"
              >{{ fullName }}</span
            >
          </v-btn>
        </template>
        <v-list class="nav-bar-list">
          <v-list-item @click="getUserProfile" class="list-item-padding">
            <v-list-item-icon class="full-name-list">
              <v-icon class>mdi-account-box-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <p
                style="
                  font-size: 16px;
                  text-align: left;
                  margin-top: 4px;
                  margin-bottom: 14px !important;
                "
              >
                {{ $t("Profile") }}
              </p>
            </v-list-item-title>
          </v-list-item>

          <v-divider style="margin-top: 6px"></v-divider>
          <v-list-item @click="changePWdialog()" class="list-item-padding">
            <v-list-item-icon class="full-name-list">
              <v-icon class>mdi-shield-key-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <p
                style="
                  font-size: 16px;
                  text-align: left;
                  margin-top: 4px;
                  margin-bottom: 14px !important;
                "
              >
                {{ $t("changepw") }}
              </p>
            </v-list-item-title>
          </v-list-item>
          <v-divider style="margin-top: 6px"></v-divider>
          <v-list-item @click="logout()" class="list-item-padding">
            <v-list-item-icon class="full-name-list">
              <v-icon class>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <p
                style="
                  font-size: 16px;
                  text-align: left;
                  margin-top: 4px;
                  margin-bottom: 14px !important;
                "
              >
                {{ $t("Signout") }}
              </p>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <!-- New Navigation Drawer -->
    <v-navigation-drawer
      width="275"
      class="Seed-drawer"
      v-model="drawer"
      app
      :floating="true"
    >
      <!-- drawer-menu -->
      <template v-slot:prepend>
        <div class="text-center">
          <img
            alt
            src="@/assets/Seed_Web_Icons/new_seed_logo.png"
            class="mt-5"
            width="80"
            height="77"
          />
        </div>
        <div class="mt-3">
          <v-subheader style="text-align: left; color: #777777; height: 45px">{{
            $t("MENU")
          }}</v-subheader>
        </div>
      </template>

      <v-list
        nav
        dense
        style="overflow-y: auto; margin-bottom: 17px"
        class="sidebar_list"
      >
        <v-list-group
          v-for="item in items"
          :key="item.title"
          @click="!item.items ? $router.push(item.href) : ''"
          no-action
          append-icon
        >
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>{{ item.action }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content v-on:click="item.active = !item.active">
              <v-list-item-title style="font-size: 16px !important">{{
                $t(item.title)
              }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-icon v-show="item.items">
              <v-img
                v-if="item.title == 'trainingroadmapiso'"
                src="@/assets/newSticker.png"
                width="35"
                height="35"
                style="margin-left: -5px"
                class="blink"
              ></v-img>
              <v-icon
                :style="[
                  item.title == 'trainingroadmapiso'
                    ? { 'margin-top': '7px' }
                    : {},
                ]"
                v-if="item.active"
                >mdi-chevron-right</v-icon
              >
              <v-icon
                :style="[
                  item.title == 'trainingroadmapiso'
                    ? { 'margin-top': '7px' }
                    : {},
                ]"
                v-else
                >mdi-chevron-right</v-icon
              >
            </v-list-item-icon>
          </template>

          <v-list-item
            v-for="child in item.items"
            :key="child.title"
            @click="QRCode(child)"
          >
            <v-divider vertical class="box"></v-divider>
            <v-divider
              v-if="child.href == $route.path"
              inset
              vertical
              :class="child.title ? 'box1 overlay' : ''"
              style="min-height: 70px; margin-top: 0px"
            ></v-divider>

            <v-list-item-content v-on:click="item.active = true">
              <div
                :class="
                  item.active == true && child.href == $route.path
                    ? 'target'
                    : 'default'
                "
              >
                <span class="mb-0" style="font-size: 16px !important">{{
                  $t(child.title)
                }}</span>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>

      <div class="container">
        <div class="foot_box"></div>
        <div class="foot_box1 foot_overlay text-center">
          <img
            alt
            src="@/assets/Seed_Web_Icons/Seed_footer_img.png"
            width="180"
            height="180"
          />
          <p
            class="ml-3"
            style="margin-bottom: 0px; color: #ffff; text-align: start"
          >
            <v-icon class="mr-2 mb-1" style="color: #ffff">mdi-web</v-icon
            >www.fusionsol.com
          </p>
          <p
            class="ml-3"
            style="margin-bottom: 0px; color: #ffff; text-align: start"
          >
            <v-icon class="mr-2 mb-1" style="color: #ffff">mdi-at</v-icon
            >sales@fusionsol.com
          </p>
          <p
            class="ml-3"
            style="margin-bottom: 0px; color: #ffff; text-align: start"
          >
            <v-icon class="mr-2 mb-1" style="color: #ffff">mdi-phone</v-icon
            >+6624400408
          </p>
        </div>
      </div>
    </v-navigation-drawer>

    <v-footer height="auto" class="foot-class pl-3" app>
      <v-spacer></v-spacer>
      <span class="caption">Copyright {{ new Date().getFullYear() }}</span>
      <span class="caption" style="color: #a6cc39">&nbsp; Seed Web &nbsp;</span>
      <span class="caption">All Rights Reserved</span>
    </v-footer>

    <v-dialog v-model="profileDialog" width="1000" persistent scrollable>
      <v-card class="rounded-lg">
        <v-card-title
          style="
            font-size: 32px;
            color: #363636;
            border: 1px solid #c0c0c0;
            font-weight: bold;
          "
          class="pb-4"
        >
          <div>
            {{ $t("Profile") }}
          </div>
          <v-spacer></v-spacer>
          <div>
            <v-btn
              icon
              @click="profileDialog = false"
              color="#424242"
              style="border-radius: 8px !important"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row class="mt-4">
              <v-col cols="12" xs="12" sm="12" md="6" lg="4">
                <div>
                  <v-img
                    :src="userprofile.profileImage"
                    width="180"
                    height="180"
                    class="mx-auto"
                    style="border-radius: 50%; border: 1px solid #c0c0c0"
                    v-if="userprofile.profileImage !== null"
                  ></v-img>
                  <v-img
                    src="@/assets/defaultprofile.png"
                    width="180"
                    height="180"
                    class="mx-auto"
                    style="border-radius: 50%; border: 1px solid #c0c0c0"
                    v-if="userprofile.profileImage == null"
                  ></v-img>
                  <input
                    type="file"
                    class="d-none"
                    ref="file"
                    accept="image/*"
                    @change="uploadImg($event, 1)"
                  />
                  <v-btn
                    fab
                    small
                    @click="$refs.file.click()"
                    style="height: 40px; translate: 180px -35px; width: 40px"
                  >
                    <v-icon style="color: #a6cc39; font-size: 30px"
                      >mdi-square-edit-outline</v-icon
                    >
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="8" class="mt-5">
                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                    <label for="employeeId" class="font-style">
                      {{ $t("employeeId") }}
                    </label>
                    <v-text-field
                      disabled
                      background-color="#BBBBBB33"
                      v-model="userprofile.employeeID"
                      outlined
                      dense
                      hide-details="auto"
                      class="mt-2"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                    <label for="prefix" class="font-style">
                      {{ $t("prefix") }}
                    </label>
                    <v-combobox
                      class="mt-2"
                      :items="prefixItems"
                      outlined
                      dense
                      hide-details="auto"
                      v-model="userprofile.prefix"
                    ></v-combobox>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                    <label for="firstname" class="font-style">
                      {{ $t("firstname") }}
                    </label>
                    <v-text-field
                      v-model="userprofile.firstName"
                      outlined
                      dense
                      hide-details="auto"
                      class="mt-2"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                    <label for="lastname" class="font-style">
                      {{ $t("lastname") }}
                    </label>
                    <v-text-field
                      v-model="userprofile.lastName"
                      outlined
                      dense
                      hide-details="auto"
                      class="mt-2"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="6"
                lg="4"
                class="my-auto mx-auto"
                style="align-items: right !important"
              >
                <label for="gender" class="font-style">{{
                  $t("gender")
                }}</label>
                <v-radio-group v-model="userprofile.gender" row>
                  <v-radio
                    class="font-style_Radio"
                    :label="$t('male')"
                    value="male"
                    key="male"
                    color="#67AB30"
                  ></v-radio>
                  <v-radio
                    class="font-style_Radio"
                    :label="$t('female')"
                    value="female"
                    key="female"
                    color="#67AB30"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="8">
                <label for="email" class="font-style">
                  {{ $t("email") }}
                </label>
                <v-text-field
                  v-model="userprofile.email"
                  outlined
                  dense
                  hide-details="auto"
                  class="mt-3"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-progress-circular
              v-if="actionloading"
              indeterminate
              class="loading-circle"
              color="green"
            ></v-progress-circular>
          </v-form>
        </v-card-text>
        <v-divider class="mt-5"></v-divider>
        <v-card-actions class="mt-3 pb-4">
          <v-spacer></v-spacer>
          <v-btn
            text
            class="text-capitalize btnfont-style"
            width="100"
            style="
              border: 1px solid #c0c0c0;
              border-radius: 8px;
              color: #424242;
            "
            @click="profileDialog = false"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            width="100"
            class="text-capitalize btnfont-style btn_hover_effect"
            style="color: #ffff; border: 1px solid #a6cc39; border-radius: 8px"
            color="#A6CC39"
            @click="updateUserProfile()"
            >{{ $t("update") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogCrop"
      persistent
      width="600px"
      height="600px"
      overflow="hidden"
    >
      <v-card class="rounded-lg" overflow="hidden">
        <v-card-title
          class="white--text pb-4"
          style="background-color: #a6cc39; font-size: 40px"
          >Crop Image</v-card-title
        >
        <v-card-text
          class="pt-12"
          style="height: 550px; padding-left: 4rem; padding-right: 4rem"
        >
          <vue-cropper
            ref="cropper"
            :img="option.img"
            :output-size="option.size"
            :output-type="option.outputType"
            :info="true"
            :full="option.full"
            :can-move="option.canMove"
            :can-move-box="option.canMoveBox"
            :fixed-box="option.fixedBox"
            :original="option.original"
            :auto-crop="option.autoCrop"
            :auto-crop-width="option.autoCropWidth"
            :auto-crop-height="option.autoCropHeight"
            :center-box="option.centerBox"
            :high="option.high"
          ></vue-cropper>
        </v-card-text>
        <v-card-actions class="mt-3 pb-4" style="margin-right: 50px">
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize font-style"
            outlined
            text
            @click="CancelCrop()"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="text-capitalize font-style reset-btn"
            color="secondary"
            width="80px"
            @click="CropImage()"
            >{{ $t("submit") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Change Password -->
    <v-dialog
      v-model="changePassword"
      persistent
      width="550px"
      height="600px"
      overflow="hidden"
    >
      <v-card class="rounded-lg">
        <v-card-title
          style="font-size: 20px; color: #363636; border: 1px solid #c0c0c0"
          class="pb-4"
        >
          <div>
            <v-icon class="mr-1" style="padding-bottom: 3px"
              >mdi-shield-key-outline</v-icon
            >
            {{ $t("changepw") }}
          </div>
          <v-spacer></v-spacer>
          <div>
            <v-btn
              icon
              @click="close()"
              color="#424242"
              style="border-radius: 8px !important"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text class="mt-3">
          <v-progress-circular
            v-if="actionloading"
            indeterminate
            class="loading-circle"
            color="green"
          ></v-progress-circular>
          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  :rules="titleRules"
                  v-model="currentPW"
                  prepend-inner-icon="mdi-lock-outline"
                  placeholder="Current Password"
                  outlined
                  dense
                  hide-details="auto"
                  class="mt-3"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="newPW"
                  prepend-inner-icon="mdi-lock-open-outline"
                  placeholder="New Password"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="confirmPW"
                  prepend-inner-icon="mdi-lock-open-outline"
                  placeholder="Re-type New Password"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <div class="errormsg d-flex justify-center" v-if="errordialog">
          {{ errorMessage }}
        </div>
        <v-card-actions class="mt-3 pb-2 d-flex justify-center">
          <v-btn
            width="150"
            class="text-capitalize"
            style="color: #ffff; border: 1px solid #a6cc39; border-radius: 8px"
            color="#A6CC39"
            @click="changePW()"
            :disabled="errorFlag"
            >{{ $t("savechanges") }}</v-btn
          >
        </v-card-actions>
        <div
          class="text-decoration-underline d-flex justify-center pb-5"
          style="color: #008aff; font-size: 14px"
        >
          <a @click="navigateToLoginPage(true)">{{ $t("resetpassword") }} </a>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog width="350" v-model="qrdialog" class="qrDialog rounded-lg">
      <v-card>
        <v-card-title>
          <v-row>
            <v-col class="pl-0 pt-1" style="text-align: left">
              <v-img
                src="@/assets/newSticker.png"
                width="65"
                height="65"
                class="blink"
              ></v-img>
            </v-col>
            <v-col style="text-align: right">
              <v-btn
                icon
                @click="closeQR()"
                color="#424242"
                style="
                  border-radius: 8px !important;
                  border: 1px solid rgba(66, 66, 66, 0.05);
                  margin-bottom: 16px;
                "
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-img src="@/assets/QR.png" class="qrimg"> </v-img>
            </v-col>
          </v-row>
          <v-row>
            <p class="qrtext">"{{ $t("scanqrcode") }}"</p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="ortext">or</p>
          </v-row>
          <v-row class="d-flex justify-center">
            <p class="linetext">
              "{{ $t("addto") }} <span style="color: #a6cc39">@seedkm</span>
              {{ $t("trainingroadmapmenu") }}"
            </p>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn depressed class="closebtn" @click="closeQR()">
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <SuccessDialog
      :show="successDialog"
      title="Success"
      :text="text"
      @close="successDialog = false"
    />
  </div>
</template>

<script>
import i18n from "@/translate/i18n";
import CountryFlag from "vue-country-flag";
import axios from "axios";
import { VueCropper } from "vue-cropper";
export default {
  props: {
    notiCount: Number,
  },
  components: {
    CountryFlag,
    VueCropper,
  },
  data() {
    return {
      startdate: null,
      enddate: null,
      showSettingMaintenanceAlert: false,
      customerWebFlag: false,

      mountDone: false,
      items: [],
      expand: false,
      valid: true,
      profileDialog: false,
      tempListTitle: null,
      zIndex: 1,
      absolute: true,
      overlay: true,
      languageDialog: false,
      dateoffset: new Date().getTimezoneOffset(),
      drawer: true,
      currentflag: "",
      currentLangTitle: "",
      lang: "",
      thaiFlagIcon: true,
      engFlagIcon: false,
      userType: null,
      fullName: null,
      companyName: null,
      actionloading: false,
      // routeNames: ["Home"],
      userprofile: {
        userID: "",
        employeeID: "",
        firstName: "",
        lastName: "",
        email: "",
        gender: "",
        prefix: "",
        profileImage: "",
        fileName: "",
        fileUrl: "",
        file: null,
      },
      UserData: null,
      Permission: null,
      dialogCrop: false,
      successDialog: false,
      qrdialog: false,
      option: {
        img: "",
        size: 1,
        full: false,
        outputType: "png",
        canMove: true,
        fixedBox: true,
        original: false,
        canMoveBox: false,
        autoCrop: true,
        autoCropWidth: 800,
        autoCropHeight: 450,
        centerBox: false,
        high: true,
      },
      prefixItems: ["Mr.", "Mrs.", "Miss."],
      changePassword: false,
      currentPW: "",
      confirmPW: "",
      newPW: "",
      errorMessage: null,
      isType: true,
      storedPassword: "",
      isLogin: false,
      notMatchPw: "",
      text: "Update Profile is successful.",
      errorFlag: true,
      errordialog: false,
      // permission: {
      //   libraryFlag: true,
      //   libraryView: true
      // }
    };
  },
  watch: {
    errordialog(newVal) {
      if (!newVal || this.errordialog) {
        this.errorMessage = "";
      }
    },
    currentPW() {
      this.confirmcheck();
    },
    newPW() {
      this.confirmcheck();
    },
    confirmPW() {
      this.confirmcheck();
    },
  },
  mounted() {
    const auth = JSON.parse(
      localStorage.getItem("vivek_authenticated_user_data")
    );
    if (auth) {
      this.userType = auth.userType;
      this.fullName = auth.fullName;
      this.language = auth.language;
      this.companyName = auth.companyName;
      this.userprofile.profileImage = auth.profilePic;
      this.loop();
      this.getUserProfileMounted();
      this.getSettingMaintenance(localStorage.getItem("companyID"));
      this.looplogout();
    }

    //language data binding below.....
    let localLang = localStorage.getItem("dbLanguage");

    switch (localLang) {
      case "th":
        this.lang = 1;
        i18n.locale = "th";
        this.currentflag = "TH";
        break;
      case "vn":
        this.lang = 2;
        i18n.locale = "vn";
        this.currentflag = "VN";
        break;
      case "cn":
        this.lang = 3;
        i18n.locale = "cn";
        this.currentflag = "CN";
        break;
      case "mm":
        this.lang = 4;
        i18n.locale = "mm";
        this.currentflag = "MM";
        break;
      default:
        this.lang = 0;
        i18n.locale = "en";
        this.currentflag = "US";
        break;
    }

    (async () => {
      await this.check_menu_permission();
      this.call_permissions();
      return Promise.resolve();
    })().then(() => {
      this.mountDone = true;
    });
  },
  methods: {
    async getSettingMaintenance(companyid) {
      let that = this;

      await axios
        .get(`${that.web_url}SettingMaintenance/GetSettingMaintenanceMessage?companyID=`+companyid)
        .then(function (response) {
          if (response.data.status == 0) {
            let temp = response.data.data;

            if (
              temp.customerWebFlag === true &&
              temp.showSettingMaintenanceAlert === true
            ) {
              that.$router.push({
                path: "/login",
                query: { db_locale: i18n.locale },
              });
            }
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    goto() {
      window.open("https://www.seedkm.com/manual/", "_blank");
    },
    QRCode(access) {
      localStorage.setItem("isoaccess", access.qrcode);
      if (access.qrcode === false) {
        this.qrdialog = true;
      } else {
        this.qrdialog = false;
        this.$router.push(access.href);
      }
    },
    changePWdialog() {
      this.changePassword = true;
      this.confirmcheck();
      this.errordialog = true;
    },
    navigateToLoginPage(isLogin) {
      this.$router.push({
        path: "/login",
        name: "login",
        params: { myparam: isLogin.toString() },
      });
    },
    async confirmcheck() {
      if (this.currentPW != localStorage.getItem("Oldpassword")) {
        if (this.newPW != "" || this.confirmPW != "") {
          if (this.newPW != this.confirmPW) {
            this.errorFlag = true;
            this.errorMessage =
              "* Please enter your current password and the same confirm Password!";
          } else {
            this.errorFlag = true;
            this.errorMessage = "* Please enter your current password!";
          }
        } else {
          this.errorFlag = true;
          this.errorMessage =
            "* Please enter your current password and new Password!";
        }
      } else {
        if (this.newPW != "" || this.confirmPW != "") {
          if (this.newPW != this.confirmPW) {
            this.errorFlag = true;
            this.errorMessage = "* Please enter the same confirm Password!";
          } else {
            this.errorFlag = false;
            this.errorMessage = "";
          }
        } else {
          this.errorFlag = true;
          this.errorMessage = "* Please enter your new password!";
        }
      }
    },
    async changePW() {
      this.actionloading = true;
      let request = {
        userId: this.userprofile.userID,
        newPassword: this.confirmPW,
      };
      const res = await this.$axios.post(
        `${this.web_urlV2}Login/ChangePassword`,
        request
      );
      if (res.data.status == 0) {
        this.text = "Password is successfully changed";
        this.successDialog = true;
        this.actionloading = false;
        this.changePassword = false;
        localStorage.setItem("Oldpassword", request.newPassword);
        this.close();
        this.notMatchPw = "";
      }
    },
    type(event) {
      this.currentPW = event.target.value;
      if (this.currentPW == null || this.currentPW == "") {
        this.isType = false;
      } else {
        this.isType = true;
      }
    },
    close() {
      this.changePassword = false;
      this.currentPW = "";
      this.newPW = "";
      this.confirmPW = "";
      this.notMatchPw = "";
      this.errorMessage = null;
      this.$refs.form.resetValidation();
      this.errordialog = false;
    },
    closeQR() {
      this.qrdialog = false;
    },
    call_permissions() {
      this.items = JSON.parse(localStorage.getItem("menu_items") || "[]");
    },
    readCookie(name) {
      let nameEQ = name + "=";
      let ca = document.cookie.split(";");
      for (const element of ca) {
        let c = element;
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
    async getUserProfile() {
      let that = this;
      self.actionloading = false;
      await axios
        .get(
          `${that.web_url}User/GetUserProfileWeb?userID=` +
            localStorage.getItem("UserID")
        )
        .then(function (response) {
          if (response.data.status == 0) {
            that.profileDialog = true;
            that.UserData = response.data.data;
            that.userprofile.userID = response.data.data[0].userID;
            that.userprofile.employeeID = response.data.data[0].employeeID;
            that.userprofile.firstName = response.data.data[0].firstName;
            that.userprofile.lastName = response.data.data[0].lastName;
            that.userprofile.email = response.data.data[0].email;
            that.userprofile.gender = response.data.data[0].gender;
            that.userprofile.prefix = response.data.data[0].prefix;
            that.userprofile.profileImage = response.data.data[0].profileImage;
            //user name refresh
            that.fullName =
              response.data.data[0].prefix +
              " " +
              response.data.data[0].firstName +
              " " +
              response.data.data[0].lastName;
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    async getUserProfileMounted() {
      let that = this;
      self.actionloading = false;
      await axios
        .get(
          `${that.web_url}User/GetUserProfileWeb?userID=` +
            localStorage.getItem("UserID")
        )
        .then(function (response) {
          if (response.data.status == 0) {
            that.UserData = response.data.data;
            that.userprofile.userID = response.data.data[0].userID;
            that.userprofile.employeeID = response.data.data[0].employeeID;
            that.userprofile.firstName = response.data.data[0].firstName;
            that.userprofile.lastName = response.data.data[0].lastName;
            that.userprofile.email = response.data.data[0].email;
            that.userprofile.gender = response.data.data[0].gender;
            that.userprofile.prefix = response.data.data[0].prefix;
            that.userprofile.profileImage = response.data.data[0].profileImage;
            //user name refresh
            that.fullName =
              response.data.data[0].prefix +
              " " +
              response.data.data[0].firstName +
              " " +
              response.data.data[0].lastName;
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    async updateUserProfile() {
      let that = this;
      this.actionloading = true;
      const base64Res = await this.$axios.post(
        `${this.web_url}File/UploadFileStringBase`,
        {
          base64: this.userprofile.file,
          fileName: this.userprofile.fileName,
        }
      );
      if (this.$refs.form.validate()) {
        let request = {
          userID: that.userprofile.userID,
          employeeID: that.userprofile.employeeID,
          firstName: that.userprofile.firstName,
          lastName: that.userprofile.lastName,
          email: that.userprofile.email,
          gender: that.userprofile.gender,
          prefix: that.userprofile.prefix,
          profileImage: base64Res.data,
        };
        await that.$axios
          .post(`${that.web_url}User/UpdateUserProfileWeb`, request)
          .then(function (response) {
            if (response.data.status == 0) {
              const auth = JSON.parse(
                localStorage.getItem("vivek_authenticated_user_data")
              );
              auth.fullName =
                request.prefix +
                " " +
                request.firstName +
                " " +
                request.lastName;
              auth.email = request.email;
              localStorage.setItem(
                "vivek_authenticated_user_data",
                JSON.stringify(auth)
              );
              localStorage.setItem(
                "userName",
                JSON.stringify(
                  request.prefix +
                    " " +
                    request.firstName +
                    " " +
                    request.lastName
                )
              );
              that.fullName = auth.fullName;
              that.successDialog = true;
              that.loading = false;
              that.actionloading = false;
              that.profileDialog = false;
            }
          })
          .catch(function (err) {
            throw err;
          });
      }
    },
    imageTobase64(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.userprofile.file = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },
    loop() {
      setTimeout(() => {
        this.getInboxNotificationDataCount();
        this.loop();
      }, 30000);
    },
    looplogout() {
      setTimeout(() => {
        this.logout();
      }, this.screentime);
    },
    async getInboxNotificationDataCount() {
      let that = this;
      let com_ID = localStorage.getItem("companyID");
      if (com_ID == null) {
        //not to show error when user is not login.
        return false;
      }
      const request = {
        companyId: localStorage.getItem("companyID"),
        startDate: "",
        endDate: "",
        offset: that.dateoffset,
        userId: parseInt(localStorage.getItem("UserID")),
      };

      const response = await axios.post(
        `${that.web_urlV3}Notification/GetNotificationFromWeb`,
        request
      );
      if (response.data.data) {
        that.noti_count =
          response.data.data.filter((x) => x.watchFlag === false).length;
      }
      that.$emit("updateNotiCount", that.noti_count);
    },
    logout() {
      localStorage.setItem("vivek_authenticated_user_data", "");
      localStorage.setItem("vivek_token", "");
      localStorage.clear();
      sessionStorage.removeItem("token");
      this.$router.push({ path: "/login", query: { db_locale: i18n.locale } });
      let expire = "Thu, 01 Jan 1970 00:00:00 GMT;";
      document.cookie = "langprod=;" + ";expires=" + expire;
      document.cookie = "flagprod=;" + ";expires=" + expire;
      document.cookie = "titleprod=;" + ";expires=" + expire;
      document.cookie = "highlighted=;" + ";expires=" + expire;
    },
    go() {
      window.getApp.$emit("APP_DRAWER_TOGGLED", () => {
        this.drawer = !this.drawer;
      });
    },
    changeLocale(locale, flag, title, currentname) {
      this.currentflag = flag;
      i18n.locale = locale;
      this.lang = locale;
      this.currentLangTitle = currentname;

      localStorage.setItem("dbLanguage", locale);

      const request = {
        userID: this.userprofile.userID,
        language: locale,
      };
      this.$axios.post(`${this.web_url}User/UpdateLanguage`, request);

      document.cookie = "lang=" + locale;
      document.cookie = "flag=" + flag;
      document.cookie = "title=" + currentname; //title;
    },
    uploadImg(e, num) {
      let self = this;
      let file = e.target.files[0];
      if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
        alert("Please choose image!");
        return false;
      }
      this.userprofile.fileName = file.name;
      let reader = new FileReader();
      reader.onload = (e) => {
        let data;
        if (typeof e.target.result === "object") {
          data = window.URL.createObjectURL(new Blob([e.target.result]));
        } else {
          data = e.target.result;
        }
        if (num === 1) {
          self.option.img = data;
          self.dialogCrop = true;
        }
      };
      reader.readAsArrayBuffer(file);
    },
    CancelCrop() {
      this.dialogCrop = false;
    },
    CropImage() {
      this.$refs.cropper.getCropData((data) => {
        this.getFile(data);
        this.dialogCrop = false;
      });
    },
    getFile(e) {
      this.userprofile.fileUrl = e;
      this.userprofile.file = e;
      this.userprofile.profileImage = e;
    },
    // async checkPermission() {
    //   let companyID = localStorage.getItem("companyID");
    //   let userID = parseInt(localStorage.getItem("UserID"));
    //   var request = {
    //     companyID: companyID,
    //     userID: userID
    //   };
    //   const res = await this.$axios.post(
    //     `${this.web_url}EmployeeEXT/CheckPermissionWeb`,
    //     request
    //   );
    //  // const permissionDb = res.data.data;
    //   console.log("per ", res.data.data);
    //   this.items = this.itemsData(res.data.data);

    //   // for (let i = 0; i < this.items.length; i++) {
    //   //   if (this.items[i].items) {
    //   //     this.items[i].items.forEach(datamenu =>
    //   //       Object.keys(permissionDb).forEach(key => {
    //   //         if (key.toLowerCase().includes("flag")) {

    //   //           if (key.toLowerCase().includes(datamenu.title.toLowerCase())) {
    //   //             datamenu.permission = permissionDb[key];
    //   //           }
    //   //         }
    //   //       })
    //   //     );
    //   //   } else {
    //   //     Object.keys(permissionDb).forEach(key => {
    //   //       if (key.toLowerCase().includes("flag")) {
    //   //         if (
    //   //           key.toLowerCase().includes(this.items[i].title.toLowerCase())
    //   //         ) {
    //   //           this.items[i].permission = permissionDb[key];
    //   //         }
    //   //       }
    //   //     });
    //   //   }
    //   // }

    // },
    // itemsData(checkdata) {
    //   return [
    //     {
    //       action: "mdi-home-variant-outline",
    //       active: false,
    //       items: [
    //         {
    //           title: "library",
    //           href: "/home/library",
    //           permission: checkdata.libraryFlag
    //           },
    //         {
    //           title: "lessonlearn",
    //           href: "/home/lesson-learn",
    //           permission: checkdata.lessonLearnFlag
    //         },
    //         {
    //           title: "course",
    //           href: "/home/assignment-course",
    //           permission: checkdata.courseFlag
    //         },
    //         {
    //           title: "quiz",
    //           href: "/home/quiz",
    //           permission: checkdata.quizFlag
    //         },
    //         {
    //           title: "community",
    //           href: "/home/community",
    //            permission: checkdata.communityFlag
    //            },
    //         {
    //           title: "event",
    //           href: "/home/event",
    //           permission: checkdata.eventFlag
    //           },
    //         {
    //           title: "feedbackbox",
    //           href: "/home/feedback-box",
    //           permission: checkdata.feedbackFlag
    //         },
    //         {
    //           title: "banner",
    //           href: "/home/banner",
    //           permission: checkdata.bannerFlag
    //           }
    //       ],
    //       title: "home"
    //     },
    //     {
    //       action: "mdi-file-document",
    //       active: false,
    //       items: [
    //         {
    //           title: "useractivity",
    //           href: "/report/user-activity",
    //           permission: checkdata.userActivityFlag
    //         },
    //         {
    //           title: "usagemetrics",
    //           href: "/report/usage-metrics",
    //           permission: checkdata.usageMetricsFlag
    //         },
    //         {
    //           title: "contentviewrp",
    //         href: "/report/content-viewing",
    //         permission: checkdata.userViewFlag
    //         },
    //         {
    //           title: "requesteduserreport",
    //           href: "/report/requested-user",
    //           permission: checkdata.requestedUserFlag
    //         },
    //         {
    //           title: "ACReport",
    //           href: "/report/assignment-course",
    //           permission: checkdata.assignmentReportFlag
    //         }
    //       ],
    //       title: "report"
    //     },
    //     {
    //       action: "mdi-account",
    //       active: false,
    //       title: "usermanagement",
    //       href: "/user-management",
    //       permission: checkdata.userManagementFlag
    //     },
    //     {
    //       action: "mdi-badge-account-horizontal",
    //       active: false,
    //       title: "companyprofile",
    //       href: "/company-profile",
    //       permission: checkdata.companyProfileFlag
    //     },
    //     {
    //       action: "mdi-bell-ring",
    //       active: false,
    //       title: "sendnotification",
    //       href: "/notification-page",
    //       permission: checkdata.sendNotificationFlag
    //     },
    //     {
    //       action: "mdi-cog-outline",
    //       active: false,
    //       items: [
    //         {
    //           title: "notification",
    //           href: "/setting/notificationpage",
    //           permission: checkdata.settingNotificationFlag
    //         }
    //       ],
    //       title: "setting"
    //     }
    //   ];
    // }
  },
  computed: {
    getTranslatedPrefixItems() {
      return this.prefixItems.map((item) => this.$t(item));
    },
    languages() {
      return [
        {
          flag: "US",
          language: "en",
          title: "U.S English",
          currentname: "EN",
        },
        {
          flag: "TH",
          language: "th",
          title: "ไทย​", //thai
          currentname: "TH",
        },
        {
          flag: "VN",
          language: "vn",
          title: "Tiếng Việt", //vietnam
          currentname: "VN",
        },
        {
          flag: "MM",
          language: "mm",
          title: "မြန်မာစာ", //myanmar
          currentname: "MM",
        },
        {
          flag: "CN",
          language: "cn",
          title: "中国", //chinese
          currentname: "CN",
        },
      ];
    },
    titleRules() {
      return [(v) => !!v || ""];
    },
    // item() {
    //   return [
    //     {
    //       action: "mdi-home-variant-outline",
    //       active: false,
    //       items: [
    //         { title: this.$t("library"), href: "/home/library" },
    //         { title: this.$t("lessonlearn"), href: "/home/lesson-learn" },
    //         {
    //           title: this.$t("assignmentcourse"),
    //           href: "/home/assignment-course",
    //         },
    //         {
    //           title: this.$t("quiz"),
    //           href: "/home/quiz",
    //         },
    //         { title: this.$t("community"), href: "/home/community" },
    //         { title: this.$t("event"), href: "/home/event" },
    //         { title: this.$t("feedbackbox"), href: "/home/feedback-box" },
    //         { title: this.$t("banner"), href: "/home/banner" },
    //         { title: this.$t("store"), href: "/home/store" },
    //       ], //{ title: this.$t("deeplink"), href: "/home/deeplink",  },
    //       title: this.$t("home"),
    //     },
    //     {
    //       action: "mdi-file-document",
    //       active: false,
    //       items: [
    //         { title: this.$t("useractivity"), href: "/report/user-activity" },
    //         // { title: this.$t("usagemetrics"), href: "/report/usage-metrics" },
    //         {
    //           title: this.$t("contentviewrp"),
    //           href: "/report/content-viewing",
    //         },
    //         {
    //           title: this.$t("requesteduserreport"),
    //           href: "/report/requested-user",
    //         },
    //         { title: this.$t("ACReport"), href: "/report/assignment-course" },
    //         // {
    //         //   title: "By Type Of Knowledge",
    //         //   href: "/report/by-type-of-knowledge",
    //         // },
    //         // { title: "By Type Of Content", href: "/report/by-type-of-content" },
    //       ],
    //       title: this.$t("report"),
    //     },

    //     // {
    //     //   action: "mdi-account",
    //     //   active: false,
    //     //   title: this.$t("usermanagement"),
    //     //   href: "/user-management",
    //     // },
    //     // {
    //     //   action: 'mdi-database',
    //     //   active: false,
    //     //   items: [
    //     //     { title: 'Type Of Knowledge', href: '/MasterData/type-of-knowledge' },
    //     //     { title: 'Type Of Content', href: '/MasterData/type-of-content' }
    //     //   ],
    //     //   title: 'Master Data',
    //     // },
    //   ];
    // },

    notiItem() {
      return [
        {
          action: "mdi-cog-outline",
          active: false,
          notiItem: [
            {
              title: this.$t("notification"),
              href: "/setting/notificationpage",
            },
            {
              title: this.$t("email"),
              href: "/setting/emailpage",
            },
          ],
          title: this.$t("setting"),
        },
      ];
    },
    // passwordsMatch() {
    //   return this.newPW === this.confirmPW;
    // },
    hasText() {
      return this.notMatchPw !== null;
    },
  },
};
</script>
<style scoped>
.lang-title {
  color: #444444;
  font-size: 16px;
}
/* .normal-flag[data-v-05fb4d45] {
  margin-right: -10px;
  -webkit-transform: scale(0.43);
} */
::v-deep .v-list-item__action {
  margin-right: 5px !important;
  min-width: unset !important;
}
/* >>> .v-list-item__title {
  font-size: 18px !important;
  opacity: 1;
} */
.v-list-item__title,
.v-list-item__subtitle {
  text-overflow: ellipsis;
  white-space: unset !important;
}
@media only screen and (max-width: 1256px) {
  .drawer-menu {
    margin-top: 65px !important;
  }
}
/* ipad */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .drawer-menu {
    margin-top: 57px !important;
  }
}
/* phone */
@media only screen and (max-width: 600px) {
  .drawer-menu {
    margin-top: 57px !important;
  }
}
/* ipad pro */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1256px) and (-webkit-min-device-pixel-ratio: 2) {
  .drawer-menu {
    margin-top: 63px !important;
  }
}

/* New design change start */
::v-deep .full-name.v-btn:not(.v-btn--round).v-size--default {
  min-width: 40px;
  padding: 0 12px;
}
::v-deep .flag-name.v-btn:not(.v-btn--round).v-size--default {
  min-width: 45px;
  padding: 0px;
}
#ButtonAnimation {
  /*Reset*/
  display: inline-block;
  text-align: center;
  color: #ffffff;
  background: #a6cc39; /*default color */
  /*affects initial color */
  background: linear-gradient(
    #a6cc39,
    #a6cc39 50%,
    rgb(255, 255, 255, var(--alpha)) 0.3%,
    rgb(255, 255, 255, var(--alpha))
  );
  background-size: 100% 200%;
  transition: background 0.3s;
}
#ButtonAnimation:hover {
  background-position: 100% 100%;
  --alpha: 0.2;
}
.full-name-list.v-list-item__icon:first-child {
  margin-right: 7px !important;
}
.full-name-list.v-list-item__icon {
  margin: 5px 0 !important;
}

.list-item-padding.v-sheet.v-list {
  border-radius: 8px !important;
}
.list-item-padding.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  margin-top: 5px;
  margin-bottom: 3px;
  width: 180px;
  margin-left: 7px;
  margin-right: 7px;
}
.list-item-padding.v-list-item--link:before {
  background-color: unset;
}
.list-item-padding.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):hover {
  border-radius: 8px !important;
  background-color: rgb(0 0 0 / 3%);
}
.list-item-padding.v-list-item {
  padding: 0 2px;
  min-height: 45px !important;
}
.nav-bar-list.v-list {
  padding: 3px 0;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000000f;
  border-radius: 8px;
  opacity: 1;
}
.flag-btn-dialog:hover {
  background-color: rgb(0 0 0 / 6%);
  border-radius: 8px;
}
</style>

<style scoped>
/* For New Drawer Design*/
::v-deep .Seed-drawer {
  height: 100vh !important;
  top: 20px !important;
  max-height: calc(100% - 44px) !important;
  transform: translateX(0%) !important;
  margin-left: 1rem;
  border-radius: 16px 16px 0px 0px;
  overflow: auto !important;
}
/* width */
.Seed-drawer ::-webkit-scrollbar {
  width: 0px !important;
}
/* Track */
.Seed-drawer ::-webkit-scrollbar-track {
  background: #ffffff !important;
}
/* Handle */
.Seed-drawer ::-webkit-scrollbar-thumb {
  background: #ffffff;
}
/* Handle on hover */
.Seed-drawer ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Footer Image Inside drawer  start...*/
.container {
  width: 250px;
  height: 210px;
  position: relative; /*relative;*/
  border-radius: 10px;
  background: unset;
  bottom: 0px;
  /* bottom: 0; */
  margin-left: 12px;
}
.foot_box {
  width: 100%;
  height: 85%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  border-radius: 10px;
  background: #a6cc39;
  margin-top: 62px;
}
.foot_box1 {
  width: 90%;
  position: absolute;
  top: -60px;
  left: 0;
  opacity: 1;
  border-radius: 10px;
  background: unset !important;
}
.foot_overlay {
  z-index: 9;
  margin-left: 12px;
  margin-bottom: 10px;
  background: unset;
  margin-top: 16px;
}
/* end... */
.foot-class.theme--light.v-footer {
  background-color: rgba(0, 0, 0, 0.05) !important;
  color: rgba(0, 0, 0, 0.87);
  /* background: url("~@/assets/Component 87 – 6@2x.png") no-repeat center center;
  background-size: cover;
  object-fit: contain; */
}
.v-application .caption {
  line-height: 0.7rem !important;
  font-family: "Poppins", sans-serif !important;
}
</style>

<style scoped>
::v-deep .v-list--nav.v-list--dense .v-list-item,
.v-list--nav.v-list--dense .v-list-item::before,
.v-list--nav.v-list--dense .v-list-item > .v-ripple__container {
  background-color: unset;
  /* color: #a6cc39 !important; */
}
::v-deep .sidebar_list.v-list .v-list-item--active {
  color: #a6cc39;
}
::v-deep .v-list-item--active .v-list-item .v-list-item--link .theme--light {
  color: #a6cc39 !important;
}

::v-deep
  .v-list-group__header.v-list-item.v-list-item--active.v-list-item--link.theme--light {
  border-radius: 8px !important;
  background-color: #a6cc39;
  color: #ffffff !important;
}
::v-deep
  .v-list-group
  .v-list-group__header
  .v-list-item__icon.v-list-group__header__append-icon {
  min-width: unset !important;
}
.v-application--is-ltr
  .v-list--dense.v-list--nav
  .v-list-group--no-action
  > .v-list-group__items
  > .v-list-item:not(.v-list-item--active)
  :hover {
  color: #a6cc39 !important;
  /* animation: name duration timing-function delay iteration-count direction fill-mode;  */
}
/* >>> .v-list--nav.v-list--dense
  .v-list-item:not(:last-child):not(:only-child)
  :hover {
  color: #a6cc39 !important;
} */
::v-deep
  .v-list--nav.v-list--dense
  .v-list-item:not(:last-child):not(:only-child),
.v-list--nav .v-list-item--dense:not(:last-child):not(:only-child),
.v-list--rounded.v-list--dense .v-list-item:not(:last-child):not(:only-child),
.v-list--rounded .v-list-item--dense:not(:last-child):not(:only-child) {
  margin-bottom: 0;
}
::v-deep .target:hover {
  animation-name: rightToLeft;
  animation-duration: 0.1s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
}

::v-deep .target {
  color: #a6cc39 !important;
}
::v-deep .default {
  color: #424242 !important;
}
@keyframes rightToLeft {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(7px);
  }
}

.box {
  margin-left: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.2;
  /* background: #424242; */
  border: 1px solid #424242;
  border-color: unset !important;
}
.box1 {
  margin-left: 0.9rem;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  background: #a6cc39 !important;
  border: 3px solid #a6cc39 !important;
  color: #a6cc39 !important;
}
.overlay {
  z-index: 9;
  background: #a6cc39 !important;
  border-radius: 4px !important;
  border-color: unset !important;
}

.flagpos {
  margin-bottom: 0px !important;
}
</style>

<style scoped>
/* Profile Dialog */
::v-deep .v-dialog {
  border-radius: 35px !important; /**35px */
}
.font-style {
  font-size: 20px;
  font-weight: bold;
  color: #424242;
}
.font-style_Radio {
  font-size: 16px;
  color: #424242;
}
::v-deep .v-input__slot {
  border-radius: 10px !important;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
}
::v-deep .btnfont-style {
  font-size: 20px !important;
}
::v-deep .theme--light.v-navigation-drawer::-webkit-scrollbar {
  width: 0px !important;
}
::v-deep .theme--light.v-navigation-drawer::-webkit-scrollbar-track {
  width: 0px !important;
  background: #e6e6e6;
}
::v-deep .v-navigation-drawer__content {
  height: 100%;
  /* overflow-y: unset !important;
  overflow-x: unset !important; */
  position: relative;
}
.loading-circle {
  position: fixed;
  z-index: 5;
  right: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
}
.errormsg {
  color: #ff6060;
}
/*:prepend-icon="item.action"
  append-icon - from menu*/
</style>

<style scoped>
.qrDialog {
  overflow: hidden !important;
}
.qrimg {
  height: auto;
  width: 200px;
  margin: auto !important;
}
.qrtext {
  font-size: 16px;
  color: #424242;
  text-align: center !important;
}
.ortext {
  font-size: 16px;
  color: #424242;
  text-align: center !important;
  font-weight: bold;
}
.linetext {
  font-size: 16px;
  color: #c0c0c0;
  text-align: center !important;
}
.closebtn {
  border-radius: 12px !important;
  background-color: #a6cc39 !important;
  color: #ffffff;
  width: 100px !important;
  font-size: 14px !important;
  margin-bottom: 20px;
}
</style>

<style scoped>
@media only screen and (max-width: 1440px) {
  ::v-deep .container {
    top: 36% !important;
  }
}
@media only screen and (max-width: 1920px) {
  ::v-deep .container {
    top: 9% !important;
  }
}
@media only screen and (min-width: 1920px) and (max-device-width: 2561px) {
  ::v-deep .container {
    top: 30% !important;
  }
}
</style>

<style scoped>
.blink {
  animation: blink 1s infinite;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  49% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
</style>
